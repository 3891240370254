
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































































































.dateColor {
  color: #ff4b00;
}
